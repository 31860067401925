import React from 'react';
import DashboardContent from './DashboardContent';
import Drawer from './Drawer';

function AdminDashboard() {
  return (
    <div className='admin-dashboard'>
      <DashboardContent />
      <Drawer />
    </div>
  );
}

export default AdminDashboard;
