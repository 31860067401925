import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import LoginIcon from '@mui/icons-material/Login';
import 'react-phone-input-2/lib/style.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { adminLogin } from '../actions/authActions';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

function AdminLogin(props) {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard-admin');
    }
  }, [isAuthenticated, navigate]);

  function onSubmit(e) {
    e.preventDefault();
    const newLogin = {
      email: email,
      password: password,
    };
    dispatch(adminLogin(newLogin));
  }

  return (
    <div className='flex lg:flex-row flex-col items-center justify-center lg:w-1/3 w-11/12  my-auto shadow-lg bg-gray-200 rounded-xl'>
      <div className='flex flex-col items-center justify-center text-center w-full p-6'>
        <p className='h2 mb-4 text-gray-600'>Admin Login</p>
        <form onSubmit={onSubmit} className='w-full'>
          <Box className='flex-1 items-center justify-center w-full'>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  id='outlined-basic'
                  label='Email'
                  type='email'
                  variant='outlined'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className='w-full'
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id='outlined-basic'
                  label='Password'
                  type='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant='outlined'
                  className='w-full'
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Button
                  type='submit'
                  variant='contained'
                  className='w-full mx-auto'
                  disableElevation
                  disabled={!email || !password}
                  startIcon={<LoginIcon />}
                >
                  Login
                </Button>
                <div className='w-full items-left justify-start pl-0 text-left'>
                  <Button href='#' className='text-sky-300' size='small'>
                    Forgot password?
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </form>
      </div>
      {props.errors.user && (
        <Snackbar
          open={true}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
        >
          <Alert severity='error' sx={{ width: '100%' }}>
            Invalid Email or Password
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  errors: state.errors,
});
export default connect(mapStateToProps)(AdminLogin);
