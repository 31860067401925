import React from 'react';
import { StyledTooltip } from './Drawer';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const DeleteButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'red',
  '&:hover': {
    backgroundColor: '#f11d1d',
  },
}));
function AccountListItem({
  onDeleteModalOpen,
  fname,
  lname,
  email,
  phone,
  id,
}) {
  return (
    <div className='account-list-item'>
      <div className='account-profile-container'>
        <div className='account-avatar-container'>
          <img
            src='https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png?20170328184010'
            alt=''
            className='account-avatar'
          />
        </div>
        <div className='account-detail-container'>
          <p className='h5'>
            {fname} {lname}
          </p>
          <p className='h6'>{email}</p>
          <p className='h6'>{phone}</p>
        </div>
      </div>
      <div className='account-menu-container'>
        <div className='header-menu-item m-3'>
          <StyledTooltip title='Delete' placement='bottom'>
            <DeleteButton
              className='drawer-button bg-sky-500'
              color='primary'
              size='medium'
              onClick={() => onDeleteModalOpen(id)}
            >
              <DeleteForeverIcon fontSize='inherit' className='menu-icon' />
            </DeleteButton>
          </StyledTooltip>
        </div>
      </div>
    </div>
  );
}

export default AccountListItem;
