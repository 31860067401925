import axios from 'axios';
import {
  ACCOUNT_LOADING,
  CLEAR_CURRENT_ACCOUNT,
  GET_ACCOUNTS,
  GET_ERRORS,
  GET_NEW_ACCOUNTS,
  DELETE_ACCOUNT,
} from '../actionTypes/actionTypes';

export const getAccounts = () => (dispatch) => {
  dispatch(setaccountloading());
  axios
    .get('/api/admin/accounts')
    .then((res) => {
      dispatch({
        type: GET_ACCOUNTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ACCOUNTS,
        payload: [],
      });
    });
};

export const getSearchAccounts = (searchQuery) => (dispatch) => {
  dispatch(setaccountloading());
  axios
    .get(`/api/admin/search-accounts/${searchQuery}`)
    .then((res) => {
      dispatch({
        type: GET_ACCOUNTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ACCOUNTS,
        payload: [],
      });
    });
};

export const deleteAccount = (deleteId) => (dispatch) => {
  axios
    .delete(`/api/admin/delete-account/${deleteId}`)
    .then((res) =>
      dispatch({
        type: DELETE_ACCOUNT,
        payload: deleteId,
      })
    )
    .catch((err) => {
      let message =
        typeof err.response !== 'undefined' ? err.response.data : null;
      dispatch({
        type: GET_ERRORS,
        payload: message,
      });
    });
};

export const getNewAccounts = () => (dispatch) => {
  dispatch(setaccountloading());
  axios
    .get('/api/visa/new-requests')
    .then((res) => {
      dispatch({
        type: GET_NEW_ACCOUNTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_NEW_ACCOUNTS,
        payload: null,
      });
    });
};

export const setaccountloading = () => {
  return {
    type: ACCOUNT_LOADING,
  };
};

export const clearcurrentaccount = () => {
  return {
    type: CLEAR_CURRENT_ACCOUNT,
  };
};
