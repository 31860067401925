import axios from 'axios';
import { GET_ERRORS, REGISTER } from '../actionTypes/actionTypes';

export const register = (userData) => (dispatch) => {
  axios
    .post('/api/user/register', userData)
    .then((res) => {
      dispatch({
        type: REGISTER,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
