export const LOGIN = 'LOGIN';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_LOGIN_TIME = 'SET_LOGIN_TIME';
export const CLEAR_CURRENT_ACCOUNT = 'CLEAR_CURRENT_ACCOUNT';
export const LOGOUT = 'LOGOUT';
export const GET_ERRORS = 'GET_ERRORS';
export const REGISTER = 'REGISTER';
export const ADD_VISA = 'ADD_VISA';
export const GET_VISA = 'GET_VISA';
export const GET_VISAS = 'GET_VISAS';
export const GET_NEW_VISAS = 'GET_NEW_VISAS';
export const VISA_LOADING = 'VISA_LOADING';
export const DELETE_VISA = 'DELETE_VISA';
export const VISA_ONREVIEW = 'VISA_ONREVIEW';
export const VISA_APPROVED = 'VISA_APPROVED';
export const VISA_REJECTED = 'VISA_REJECTED';
export const ACCOUNT_LOADING = 'ACCOUNT_LOADING';
export const GET_ACCOUNTS = 'REGISGET_ACCOUNTSTER';
export const GET_NEW_ACCOUNTS = 'GET_NEW_ACCOUNTS';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const ACCOUNT_ERROR = 'ACCOUNT_ERROR';
