import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { StyledTooltip } from './Drawer';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import PopoverListItem from './PopoverListItem';
import PopoverReqListItem from './PopoverReqListItem';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { logout } from '../actions/authActions';
import ListItemIcon from '@mui/material/ListItemIcon';

export const ColorButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: '#0069d9',
  },
}));
function AdminHeader({ newAccounts, newVisaRequests, current_user }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [popoverEl, setpopoverEl] = useState(null);
  const [newAccPop, setnewAccPop] = useState(null);
  const [newReqPop, setnewReqPop] = useState(null);
  const dispatch = useDispatch();
  const showPopOver = (event) => {
    setpopoverEl(event.currentTarget);
  };

  const hidePopOver = () => {
    setpopoverEl(null);
  };
  const open = Boolean(popoverEl);
  const newAccOpen = Boolean(newAccPop);
  const newReqOpen = Boolean(newReqPop);
  const id = open ? 'simple-popover' : undefined;
  const newAccId = open ? 'simple-popover' : undefined;
  const newReqId = open ? 'simple-popover' : undefined;
  return (
    <div className='admin-dashboard-header'>
      <div className='header-profile-container'>
        <div className='profile-avatar-container'>
          <img
            src='https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png?20170328184010'
            alt=''
            className='profile-avatar'
          />
        </div>
        <div className='profile-detail-container text-left'>
          <p className='h3 px-3 pt-3'>
            {current_user.fname} {current_user.lname}
          </p>
          <Chip label='Admin' className='mx-3' color='primary' />
          <StyledTooltip title='Account Settings' placement='bottom'>
            <ColorButton
              className='drawer-button bg-sky-500'
              color='primary'
              size='medium'
              aria-describedby={id}
              onClick={showPopOver}
            >
              <SettingsIcon fontSize='small' className='menu-icon' />
            </ColorButton>
          </StyledTooltip>
          <Menu
            anchorEl={popoverEl}
            id='account-menu'
            open={open}
            onClose={hidePopOver}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: '0.6em',
                ml: '-0.5em',
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: '-3em',
                  mr: 3,
                  px: '0.5em',
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) translateX(-890%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          >
            <MenuItem onClick={() => dispatch(logout())}>
              <ListItemIcon>
                <LogoutIcon fontSize='small' />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className='header-menu-container'>
        <div className='header-menu-item m-3'>
          <StyledTooltip title='New Requests' placement='bottom'>
            <ColorButton
              className='drawer-button bg-sky-500'
              color='primary'
              size='medium'
              onClick={(e) => setnewReqPop(e.currentTarget)}
            >
              <Badge badgeContent={newVisaRequests.length} color='warning'>
                <PendingActionsIcon fontSize='inherit' className='menu-icon' />
              </Badge>
            </ColorButton>
          </StyledTooltip>
          <Popover
            id={newReqId}
            open={newReqOpen}
            anchorEl={newReqPop}
            onClose={() => setnewReqPop(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{ height: 400 }}
          >
            <div className='w-full px-3 py-1 text-left'>
              <p className='h4'>New Requests</p>
            </div>
            {newVisaRequests.length < 1 ? (
              <p className='h6 text-center'>No New Requests</p>
            ) : (
              <div className='flex flex-col items-center h-60 w-full px-2 overflow-y-scroll'>
                {newVisaRequests.map((visa, index) => {
                  return (
                    <PopoverReqListItem
                      fname={visa.fname}
                      mname={visa.mname}
                      gender={visa.gender}
                      passportno={visa.passportno}
                      setModalOpen={setModalOpen}
                      key={index}
                    />
                  );
                })}
              </div>
            )}
            <div className='w-full text-center items-center my-2'>
              <Link to='/pending-requests'>
                <Button variant='contained' color='primary'>
                  View All
                </Button>
              </Link>
            </div>
          </Popover>
        </div>
        <div className='header-menu-item m-3'>
          <StyledTooltip title='New Accounts' placement='bottom'>
            <ColorButton
              className='drawer-button bg-sky-500'
              color='primary'
              size='medium'
              onClick={(e) => setnewAccPop(e.currentTarget)}
            >
              <Badge badgeContent={newAccounts.length} color='warning'>
                <AccountCircleIcon fontSize='inherit' className='menu-icon' />
              </Badge>
            </ColorButton>
          </StyledTooltip>
          <Popover
            id={newAccId}
            open={newAccOpen}
            anchorEl={newAccPop}
            onClose={() => setnewAccPop(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{ height: 400 }}
          >
            <div className='w-full px-3 py-1 text-left'>
              <p className='h4'>New Accounts</p>
            </div>
            {newAccounts.length < 1 ? (
              <p className='h6 text-center'>No New Accounts</p>
            ) : (
              <div className='flex flex-col items-center h-60 w-full px-2 overflow-y-scroll'>
                {newAccounts.map((account, index) => {
                  return (
                    <PopoverListItem
                      fname={account.fname}
                      lname={account.lname}
                      email={account.email}
                      phone={account.phone}
                      id={account.id}
                      key={index}
                    />
                  );
                })}
              </div>
            )}
            <div className='w-full text-center items-center my-2'>
              <Link to='/accounts'>
                <Button variant='contained' color='primary'>
                  View All
                </Button>
              </Link>
            </div>
          </Popover>
        </div>
      </div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <div className=' w-1/2 absolute top-0 transform translate-x-1/2 translate-y-1/2 bg-gray-100 p-3 rounded-lg'>
            <div className='modal-header text-left px-3'>
              <p className='h3'>Request Details</p>
            </div>
            <div className='modal-body'></div>
            <div className='modal-footer flex flex-row justify-end'>
              <div className='header-menu-item mx-1 my-1'>
                <Button
                  variant='contained'
                  color='error'
                  onClick={() => setModalOpen(false)}
                >
                  Decline
                </Button>
              </div>
              <div className='header-menu-item mx-1 my-1'>
                <Button variant='contained' onClick={() => setModalOpen(false)}>
                  Approve
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default AdminHeader;
