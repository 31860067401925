import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { errorReducer } from './errorReducer';
import { registerReducer } from './registerReducer';
import { accountReducer } from './accountReducer';
import { visaReducer } from './visaReducer';

export const masterReducer = combineReducers({
  auth: authReducer,
  register: registerReducer,
  errors: errorReducer,
  accounts: accountReducer,
  visa: visaReducer,
});
