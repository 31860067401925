import React, { useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import { StyledTooltip } from './Drawer';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import BookIcon from '@mui/icons-material/Book';
import PortraitIcon from '@mui/icons-material/Portrait';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { logout } from '../actions/authActions';
import { addVisa } from '../actions/visaActions';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const Input = styled('input')({
  display: 'none',
});
export const ColorButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: '#0069d9',
  },
}));

function UserHeader({ current_user }) {
  const [fname, setFname] = useState('');
  const [mname, setMname] = useState('');
  const [lname, setLname] = useState('');
  const [nationality, setNationality] = useState('');
  const errors = useSelector((state) => state.errors);
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [mahpassportno, setMahPassportno] = useState('');
  const [passportno, setPassportno] = useState('');
  const [doi, setDoi] = useState('');
  const [doe, setDoe] = useState('');
  const [passportFile, setPassportFile] = useState(null);
  const [portraitFile, setPortraitFile] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [popoverEl, setpopoverEl] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const dispatch = useDispatch();
  const closeSnack = (event, reason) => {
    setSnackOpen(false);
  };
  const showPopOver = (event) => {
    setpopoverEl(event.currentTarget);
  };

  const hidePopOver = () => {
    setpopoverEl(null);
  };

  const open = Boolean(popoverEl);
  const id = open ? 'simple-popover' : undefined;

  const changeBDate = (newValue) => {
    setDob(newValue);
    console.log(newValue);
  };
  const changeIDate = (newValue) => {
    setDoi(newValue);
  };
  const changeEDate = (newValue) => {
    setDoe(newValue);
  };
  function onSubmit(e) {
    e.preventDefault();
    const Dob = `${dob.getDate()}/${dob.getMonth()}/${dob.getFullYear()}`;
    const Doi = `${doi.getDate()}/${doi.getMonth()}/${doi.getFullYear()}`;
    const Doe = `${doe.getDate()}/${doe.getMonth()}/${doe.getFullYear()}`;
    let date = new Date();
    date = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
    const data = new FormData();
    if (mahpassportno === '') {
      setMahPassportno(null);
    }
    data.append('fname', fname);
    data.append('mname', mname);
    data.append('lname', lname);
    data.append('nationality', nationality);
    data.append('dob', Dob);
    data.append('gender', gender);
    data.append('mahpassportno', mahpassportno);
    data.append('passportno', passportno);
    data.append('doi', Doi);
    data.append('doe', Doe);
    data.append('filedby', current_user.id);
    data.append('date', date);
    data.append('portrait', portraitFile);
    data.append('passport', passportFile);
    dispatch(addVisa(data));
    if (!Object.keys(errors).some((k) => errors[k])) {
      setSnackOpen(true);
      setFname('');
      setMname('');
      setLname('');
      setNationality('');
      setDob('');
      setGender('');
      setMahPassportno('');
      setPassportno('');
      setDoi('');
      setDoe('');
      setPortraitFile(null);
      setPassportFile(null);
    }
  }
  return (
    <div className='admin-dashboard-header'>
      <div className='header-profile-container'>
        <div className='profile-avatar-container'>
          <img
            src='https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png?20170328184010'
            alt=''
            className='profile-avatar'
          />
        </div>
        <div className='profile-detail-container text-left'>
          <p className='h3 px-3 pt-3'>
            {current_user.fname} {current_user.lname}
          </p>
          <div className='ml-3 mr-1'>
            <StyledTooltip title='Account Settings' placement='bottom'>
              <ColorButton
                className='drawer-button bg-sky-500'
                color='primary'
                size='medium'
                aria-describedby={id}
                onClick={showPopOver}
                sx={{ marginRight: '1em' }}
              >
                <SettingsIcon fontSize='small' className='menu-icon' />
              </ColorButton>
            </StyledTooltip>
            <Popover
              id={id}
              open={open}
              anchorEl={popoverEl}
              onClose={hidePopOver}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div className='popover-content-container py-2 px-1'>
                <Button
                  variant='standard'
                  startIcon={<LogoutIcon color='error' />}
                  color='error'
                  onClick={() => dispatch(logout())}
                >
                  Logout
                </Button>
              </div>
            </Popover>
            <Button
              variant='contained'
              color='primary'
              startIcon={<PersonAddAlt1Icon />}
              onClick={() => setModalOpen(true)}
            >
              Apply For Visa
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <div className='modal-content'>
            <form onSubmit={onSubmit}>
              <div className='modal-header text-left px-3'>
                <p className='h4'>Edit Details</p>
              </div>
              <div className='modal-body h-4/5'>
                <Box className='flex-1 items-center justify-center w-full'>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                      <TextField
                        id='outlined-basic'
                        label='First Name'
                        variant='outlined'
                        className='w-full'
                        value={fname}
                        onChange={(e) => setFname(e.target.value)}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        id='outlined-basic'
                        label='Father Name'
                        variant='outlined'
                        className='w-full'
                        value={mname}
                        onChange={(e) => setMname(e.target.value)}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        id='outlined-basic'
                        label='Grandfather Name'
                        variant='outlined'
                        className='w-full'
                        value={lname}
                        onChange={(e) => setLname(e.target.value)}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <TextField
                        id='outlined-basic'
                        label='Nationality'
                        variant='outlined'
                        className='w-full'
                        value={nationality}
                        onChange={(e) => setNationality(e.target.value)}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          label='Date of Birth'
                          inputFormat='dd/MM/yyyy'
                          value={dob}
                          onChange={changeBDate}
                          renderInput={(params) => (
                            <TextField className='w-full' {...params} />
                          )}
                          sx={{ width: '100%' }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item md={gender === 'Female' ? 4 : 6} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>
                          Gender
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          label='Gender'
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <MenuItem value={'Male'}>Male</MenuItem>
                          <MenuItem value={'Female'}>Female</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={gender === 'Female' ? 4 : 6} sm={12} xs={12}>
                      <TextField
                        id='outlined-basic'
                        label='Passport No.'
                        variant='outlined'
                        className='w-full'
                        value={passportno}
                        onChange={(e) => setPassportno(e.target.value)}
                      />
                    </Grid>
                    {gender === 'Female' ? (
                      <Grid item md={4} sm={12} xs={12}>
                        <TextField
                          id='outlined-basic'
                          label='Mahrum Passport No.'
                          variant='outlined'
                          className='w-full'
                          value={mahpassportno}
                          onChange={(e) => setMahPassportno(e.target.value)}
                        />
                      </Grid>
                    ) : null}
                    <Grid item md={6} sm={12} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          label='Date of Issue'
                          inputFormat='dd/MM/yyyy'
                          value={doi}
                          onChange={changeIDate}
                          renderInput={(params) => (
                            <TextField className='w-full' {...params} />
                          )}
                          sx={{ width: '100%' }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          label='Date of Expiry'
                          inputFormat='dd/MM/yyyy'
                          value={doe}
                          onChange={changeEDate}
                          renderInput={(params) => (
                            <TextField className='w-full' {...params} />
                          )}
                          sx={{ width: '100%' }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className='admin-dashboard-main-item'>
                        <div className='file-upload-container'>
                          <div className='upload-icon-container p-3 w-fit text-center items-center flex justify-center mx-auto rounded-full border border-dashed border-gray-400'>
                            {portraitFile !== null ? (
                              <CheckRoundedIcon />
                            ) : (
                              <PortraitIcon />
                            )}
                          </div>
                          <Box
                            sx={{
                              color: 'text.secondary',
                              fontSize: 18,
                              marginX: 'auto',
                            }}
                          >
                            Upload Photo
                          </Box>
                          <Box>
                            <div className='file-upload-button-container'>
                              <label htmlFor='portrait-file'>
                                <Input
                                  accept='image/*'
                                  id='portrait-file'
                                  name='portrait'
                                  type='file'
                                  onChange={(e) =>
                                    setPortraitFile(e.target.files[0])
                                  }
                                />
                                <Button
                                  sx={{ borderRadius: '2em' }}
                                  variant='contained'
                                  component='span'
                                  disableElevation
                                >
                                  Choose File
                                </Button>
                              </label>
                            </div>
                          </Box>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className='admin-dashboard-main-item'>
                        <div className='file-upload-container'>
                          <div className='upload-icon-container p-3 w-fit text-center items-center flex justify-center mx-auto rounded-full border border-dashed border-gray-400'>
                            {passportFile !== null ? (
                              <CheckRoundedIcon />
                            ) : (
                              <BookIcon />
                            )}
                          </div>
                          <Box sx={{ color: 'text.secondary', fontSize: 18 }}>
                            Upload Passport
                          </Box>
                          <Box>
                            <div className='file-upload-button-container'>
                              <label htmlFor='passport-file'>
                                <Input
                                  accept='image/*'
                                  id='passport-file'
                                  name='passport'
                                  type='file'
                                  onChange={(e) =>
                                    setPassportFile(e.target.files[0])
                                  }
                                />
                                <Button
                                  sx={{ borderRadius: '2em' }}
                                  variant='contained'
                                  component='span'
                                  disableElevation
                                >
                                  Choose File
                                </Button>
                              </label>
                            </div>
                          </Box>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </div>
              <div className='modal-footer flex flex-row justify-end'>
                <div className='header-menu-item mx-1'>
                  <Button
                    variant='contained'
                    onClick={() => setModalOpen(false)}
                    sx={{
                      backgroundColor: '#877b7a',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#6c6767',
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div className='header-menu-item mx-1'>
                  <Button
                    type='submit'
                    variant='contained'
                    disabled={
                      fname === '' ||
                      mname === '' ||
                      lname === '' ||
                      nationality === '' ||
                      dob === '' ||
                      gender === '' ||
                      (gender === 'Female' && mahpassportno === '') ||
                      passportno === '' ||
                      doi === '' ||
                      doe === '' ||
                      portraitFile === null ||
                      passportFile === null
                        ? true
                        : false
                    }
                  >
                    Done
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
      <Snackbar
        open={snackOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={closeSnack}
      >
        <Alert onClose={closeSnack} severity='success' sx={{ width: '100%' }}>
          Application Sent Successfully
        </Alert>
      </Snackbar>
      {errors.checkpassport && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
        >
          <Alert
            severity='warning'
            autoHideDuration={5000}
            sx={{ width: '100%' }}
          >
            There is already a request filed for this passport!
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

export default UserHeader;
