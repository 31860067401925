import {
  GET_VISAS,
  ADD_VISA,
  VISA_LOADING,
  DELETE_VISA,
} from '../actionTypes/actionTypes';

const initialState = {
  visas: [],
  loading: false,
};

export const visaReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_VISA:
      return {
        ...state,
        visas: [action.payload, ...state.visas],
      };
    case VISA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DELETE_VISA:
      return {
        ...state,
        visas: state.visas.filter((visa) => visa.id !== action.payload),
      };
    case GET_VISAS:
      return {
        ...state,
        visas: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
