import { LOGIN, LOGOUT, SET_CURRENT_USER } from '../actionTypes/actionTypes';
import isEmpty from '../validation/isEmpty';

const initialState = {
  isAuthenticated: false,
  current_user: {},
  loginTime: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        current_user: action.payload.decoded,
        loginTime: new Date().getTime(),
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        current_user: action.payload.decoded,
        loginTime: action.payload.loginTime,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        current_user: action.payload.decoded,
        loginTime: action.payload.loginTime,
      };
    default:
      return state;
  }
};
