import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import FormControl from '@mui/material/FormControl';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { register } from '../actions/registerAction';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

function Signup(props) {
  const [passwordType, setPasswordType] = useState('password');
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState({});
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [snackOpen, setSnackOpen] = useState(false);
  const dispatch = useDispatch();
  const closeSnack = (event, reason) => {
    setSnackOpen(false);
  };
  useEffect(() => {
    setErrors(props.errors);
    setUser(props.register.user);
    if (!Object.keys(props.errors).some((k) => props.errors[k])) {
      setSnackOpen(true);
      setFname('');
      setLname('');
      setEmail('');
      setPhone('');
      setPassword('');
    }
  }, [props.errors, props.register.user, errors, user]);
  function onSubmit(e) {
    e.preventDefault();
    if (phone === '') {
      setPhone(null);
    }
    const newUser = {
      fname: fname,
      lname: lname,
      phone: phone,
      email: email,
      password: password,
    };
    dispatch(register(newUser));
  }
  return (
    <div className='login-container'>
      <div className='login-form-container'>
        <p className='h2 mb-4 text-gray-600'>Sign Up</p>
        <form onSubmit={onSubmit} className='w-full'>
          <Box className='flex-1 items-center justify-center w-full'>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  id='outlined-basic'
                  label='First Name'
                  variant='outlined'
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                  className='w-full'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id='outlined-basic'
                  label='Last Name'
                  variant='outlined'
                  value={lname}
                  onChange={(e) => setLname(e.target.value)}
                  className='w-full'
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  id='outlined-basic'
                  label='Email'
                  type='email'
                  variant='outlined'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className='w-full'
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <PhoneInput
                  inputProps={{
                    name: 'phone',
                    required: true,
                    placeholder: 'Phone Number',
                    onChange: (e) => setPhone(e.target.value),
                  }}
                  country='et'
                  enableSearch={true}
                  containerClass='h-14 rounded-md border-gray-300 border active:border-sky-300'
                  inputStyle={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'transparent',
                    paddingLeft: '4em',
                  }}
                  buttonStyle={{ width: '15%', backgroundColor: 'transparent' }}
                  value={phone}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl className='w-full' variant='outlined'>
                  <InputLabel htmlFor='outlined-adornment-password'>
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id='outlined-basic'
                    label='Password'
                    type={passwordType}
                    variant='outlined'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onMouseDown={() => setPasswordType('text')}
                          onMouseUp={() => setPasswordType('password')}
                          edge='end'
                        >
                          <Visibility />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <Button
                  type='submit'
                  variant='contained'
                  className='w-full'
                  disabled={
                    fname === '' ||
                    lname === '' ||
                    password === '' ||
                    email === ''
                      ? true
                      : false
                  }
                  disableElevation
                >
                  Sign Up
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </div>
      <div className='signup-refer-container'>
        <p className='h5 text-gray-200 mb-6'>Already have an account?</p>
        <Link to='/'>
          <button
            type='submit'
            variant='contained'
            className='refer-btn'
            disableElevation
          >
            Login
          </button>
        </Link>
      </div>
      {Object.keys(props.register.user).length > 0 ? (
        <Snackbar
          open={snackOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={closeSnack}
        >
          <Alert onClose={closeSnack} severity='success' sx={{ width: '100%' }}>
            Account Successfully Created!
          </Alert>
        </Snackbar>
      ) : null}
      {props.errors.checkemail && (
        <Snackbar
          open={true}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={7000}
        >
          <Alert severity='warning' sx={{ width: '100%' }}>
            There Is Already An Account With That Email!
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  register: state.register,
});
export default connect(mapStateToProps)(Signup);
