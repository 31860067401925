import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { Box, ThemeProvider, createTheme } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import AdminHeader from './AdminHeader';
import { getAccounts } from '../actions/accountActions';
import Skeleton from '@mui/material/Skeleton';
import { getVisas } from '../actions/visaActions';

const theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A',
    },
    action: {
      active: '#001E3C',
    },
    success: {
      dark: '#009688',
    },
  },
});
function DashboardContent() {
  const navigate = useNavigate();
  const { isAuthenticated, current_user, loginTime } = useSelector(
    (state) => state.auth
  );
  const { accounts, accLoading } = useSelector((state) => state.accounts);
  const { visas, visaLoading } = useSelector((state) => state.visa);
  const approvedVisas = visas.filter((visa) => visa.status === 'approved');
  const declinedVisas = visas.filter((visa) => visa.status === 'declined');
  let newAccounts = accounts.filter((account) => account.createdat > loginTime);
  let newVisaRequests = visas.filter((visa) => visa.createdat > loginTime);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/admin-login');
    }
    dispatch(getAccounts());
    dispatch(getVisas());
  }, [isAuthenticated, navigate, dispatch]);
  return (
    <div className='admin-dashboard-content'>
      {accLoading || visaLoading ? (
        <Skeleton
          variant='rectangular'
          animation='wave'
          width={500}
          height={118}
        />
      ) : (
        <AdminHeader
          newAccounts={newAccounts}
          newVisaRequests={newVisaRequests}
          current_user={current_user}
        />
      )}
      <hr />
      <div className='admin-dashboard-main'>
        <div className='admin-dashboard-main-item'>
          <Link to='/accounts'>
            {accLoading || visaLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                width={210}
                height={118}
              />
            ) : (
              <ThemeProvider theme={theme}>
                <Box
                  sx={{
                    bgcolor: 'background.paper',
                    boxShadow: 1,
                    borderRadius: 2,
                    p: 2,
                    minWidth: 20,
                  }}
                >
                  <Box sx={{ color: 'text.secondary', fontSize: 24 }}>
                    <SwitchAccountIcon className='menu-icon' fontSize='large' />
                    &nbsp;&nbsp; Accounts Created
                  </Box>
                  <Box
                    sx={{
                      color: 'text.primary',
                      fontSize: 34,
                      fontWeight: 'medium',
                    }}
                  >
                    {accounts.length}
                  </Box>
                </Box>
              </ThemeProvider>
            )}
          </Link>
        </div>
        <div className='admin-dashboard-main-item'>
          <Link to='/pending-requests'>
            {accLoading || visaLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                width={210}
                height={118}
              />
            ) : (
              <ThemeProvider theme={theme}>
                <Box
                  sx={{
                    bgcolor: 'background.paper',
                    boxShadow: 1,
                    borderRadius: 2,
                    p: 2,
                    minWidth: 20,
                  }}
                >
                  <Box sx={{ color: 'text.secondary', fontSize: 24 }}>
                    <ContentPasteIcon className='menu-icon' fontSize='large' />
                    &nbsp;&nbsp; Visas Issued
                  </Box>
                  <Box
                    sx={{
                      color: 'text.primary',
                      fontSize: 34,
                      fontWeight: 'medium',
                    }}
                  >
                    {approvedVisas.length}
                  </Box>
                </Box>
              </ThemeProvider>
            )}
          </Link>
        </div>
        <div className='admin-dashboard-main-item'>
          {accLoading || visaLoading ? (
            <Skeleton
              variant='rectangular'
              animation='wave'
              width={210}
              height={118}
            />
          ) : (
            <ThemeProvider theme={theme}>
              <Box
                sx={{
                  bgcolor: 'background.paper',
                  boxShadow: 1,
                  borderRadius: 2,
                  p: 2,
                  minWidth: 20,
                }}
              >
                <Box sx={{ color: 'text.warning', fontSize: 24 }}>
                  <ContentPasteOffIcon className='menu-icon' fontSize='large' />
                  &nbsp;&nbsp; Visas Declined
                </Box>
                <Box
                  sx={{
                    color: 'text.primary',
                    fontSize: 34,
                    fontWeight: 'medium',
                  }}
                >
                  {declinedVisas.length}
                </Box>
              </Box>
            </ThemeProvider>
          )}
        </div>
      </div>
    </div>
  );
}

export default DashboardContent;
