import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Components/Login';
import Signup from './Components/Signup';
import AdminDashboard from './Components/AdminDashboard';
import AccountsScreen from './Components/AccountsScreen';
import PendingRequestsScreen from './Components/PendingRequestsScreen';
import UserDashboard from './Components/UserDashboard';
import AdminLogin from './Components/AdminLogin';
import { Provider } from 'react-redux';
import { store } from './store/store';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className='App'>
          <Routes>
            <Route exact path='/' element={<Login />} />
            <Route exact path='/admin-login' element={<AdminLogin />} />
            <Route exact path='/signup' element={<Signup />} />
            <Route exact path='/dashboard-admin' element={<AdminDashboard />} />
            <Route exact path='/accounts' element={<AccountsScreen />} />
            <Route exact path='/dashboard-user' element={<UserDashboard />} />
            <Route
              exact
              path='/pending-requests'
              element={<PendingRequestsScreen />}
            />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
