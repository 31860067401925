import React from 'react';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const DeleteButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'red',
  '&:hover': {
    backgroundColor: '#f11d1d',
  },
}));
function PopoverListItem({ fname, lname, email, phone, id }) {
  return (
    <div className='account-list-item'>
      <div className='account-profile-container'>
        <div className='account-avatar-container'>
          <img
            src='https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png?20170328184010'
            alt=''
            className='lg:w-10 lg:h-10 rounded-full'
          />
        </div>
        <div className='account-detail-container'>
          <p className='fs-5'>
            {fname} {lname}
          </p>
          <p className='fs-6'>{email}</p>
          <p className='fs-6'>{phone}</p>
        </div>
      </div>
    </div>
  );
}

export default PopoverListItem;
