import React from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const DeleteButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'red',
  '&:hover': {
    backgroundColor: '#f11d1d',
  },
}));
function PendingRequestsListItem({
  id,
  fname,
  mname,
  lname,
  nationality,
  dob,
  gender,
  mahpassportno,
  passportno,
  doi,
  doe,
  portrait,
  passport,
  reciept,
  reason,
  date,
  status,
  visa,
  visano,
  visadoe,
  viewDetail,
}) {
  return (
    <div className='account-list-item'>
      <div className='account-profile-container'>
        <div className='account-avatar-container'>
          <img
            src='https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png?20170328184010'
            alt=''
            className='account-avatar'
          />
        </div>
        <div className='account-detail-container'>
          <p className='h5'>
            {fname} {mname} {lname}
          </p>
          <p className='h6'>{gender}</p>
          <p className='h6'>{status}</p>
        </div>
      </div>
      <div className='account-menu-container'>
        <div className='header-menu-item m-3'>
          <Button
            variant='contained'
            onClick={() =>
              viewDetail(
                id,
                fname,
                mname,
                lname,
                nationality,
                dob,
                gender,
                mahpassportno,
                passportno,
                doi,
                doe,
                portrait,
                passport,
                reciept,
                date,
                status,
                visa,
                visano,
                visadoe,
                reason
              )
            }
          >
            View Application
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PendingRequestsListItem;
