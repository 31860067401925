import {
  ACCOUNT_LOADING,
  GET_ACCOUNTS,
  DELETE_ACCOUNT,
} from '../actionTypes/actionTypes';

const initialState = {
  accounts: [],
  loading: false,
};

export const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
        loading: false,
      };
    case DELETE_ACCOUNT:
      return {
        ...state,
        accounts: state.accounts.filter(
          (account) => account.id !== action.payload
        ),
      };
    default:
      return state;
  }
};
