import React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import SpeedIcon from '@mui/icons-material/Speed';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { Link } from 'react-router-dom';

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function Drawer() {
  return (
    <div className='drawer-container'>
      <div className='drawer-content-item'>
        <StyledTooltip title='Dashboard' placement='right'>
          <Link to='/dashboard-admin'>
            <IconButton className='drawer-button' size='medium'>
              <SpeedIcon fontSize='inherit' className='drawer-icon' />
            </IconButton>
          </Link>
        </StyledTooltip>
      </div>
      <hr></hr>
      <div className='drawer-content-item'>
        <StyledTooltip title='Accounts' placement='right'>
          <Link to='/accounts'>
            <IconButton className='drawer-button' size='medium'>
              <AccountCircleIcon fontSize='inherit' className='drawer-icon' />
            </IconButton>
          </Link>
        </StyledTooltip>
      </div>
      <div className='drawer-content-item'>
        <StyledTooltip title='Pending Requests' placement='right'>
          <Link to='/pending-requests'>
            <IconButton className='drawer-button' color='default' size='medium'>
              <PendingActionsIcon fontSize='inherit' className='drawer-icon' />
            </IconButton>
          </Link>
        </StyledTooltip>
      </div>
    </div>
  );
}

export default Drawer;
