import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AccountListItem from './AccountListItem';
import AdminHeader from './AdminHeader';
import Drawer from './Drawer';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import {
  deleteAccount,
  getAccounts,
  getSearchAccounts,
} from '../actions/accountActions';
function AccountsScreen(props) {
  const navigate = useNavigate();
  const errors = props.errors;
  const { isAuthenticated, current_user, loginTime } = useSelector(
    (state) => state.auth
  );
  const { accounts, accLoading } = useSelector((state) => state.accounts);
  const { visas, visaLoading } = useSelector((state) => state.visa);
  let newAccounts = accounts.filter((account) => account.createdat > loginTime);
  let newVisaRequests = visas.filter((visa) => visa.createdat > loginTime);
  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/admin-login');
    }
    dispatch(getAccounts());
  }, [isAuthenticated, navigate, errors, dispatch]);
  function onDeleteModalOpen(id) {
    setDeleteModalOpen(true);
    setAccountToDelete(id);
  }
  function onDelete() {
    dispatch(deleteAccount(accountToDelete));
    setDeleteModalOpen(false);
  }
  function onSearch(e) {
    setSearchQuery(e);
    if (searchQuery !== '') {
      dispatch(getSearchAccounts(searchQuery));
    } else {
      dispatch(getAccounts());
    }
  }
  return (
    <div className='admin-dashboard'>
      <div className='admin-dashboard-content'>
        {accLoading || visaLoading ? (
          <Skeleton
            variant='rectangular'
            animation='wave'
            width={500}
            height={118}
          />
        ) : (
          <AdminHeader
            newAccounts={newAccounts}
            newVisaRequests={newVisaRequests}
            current_user={current_user}
          />
        )}
        <hr />
        <div className='header-enu-item m-3'>
          <input
            type='search'
            value={searchQuery}
            onChange={(e) => onSearch(e.target.value)}
            placeholder='&#xf002;  Search'
            className='search-input'
            id=''
          />
        </div>
        <div className='accounts-list-container w-3/5'>
          <div className='accounts-list-header text-left w-full mb-4'>
            <p className='h3'>Accounts</p>
          </div>
          {typeof accounts.msg != 'undefined' || accounts.length < 1 ? (
            <p className='h4'>No Accounts Yet</p>
          ) : (
            <div className='accounts-list'>
              {accounts.map((account, index) => {
                return (
                  <AccountListItem
                    fname={account.fname}
                    lname={account.lname}
                    email={account.email}
                    phone={account.phone}
                    id={account.id}
                    onDeleteModalOpen={onDeleteModalOpen}
                    key={index}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      <Drawer />
      <Modal
        open={DeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={DeleteModalOpen}>
          <div className='absolute transform translate-x-1/2 translate-y-1/2 bg-gray-100 p-3 rounded-lg'>
            <div className='modal-header flex flex-row justify-between text-left px-3'>
              <p className='h4'>Delete Account</p>
            </div>
            <div className='modal-body'>
              <p className='h4'>
                Are you sure you want to delete this account?
              </p>
            </div>
            <div className='modal-footer flex flex-row justify-end'>
              <div className='header-menu-item mx-1 my-1'>
                <Button
                  variant='contained'
                  color='info'
                  onClick={() => setDeleteModalOpen(false)}
                >
                  Cancel
                </Button>
              </div>
              <div className='header-menu-item mx-1 my-1'>
                <Button variant='contained' color='error' onClick={onDelete}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  register: state.register,
});
export default connect(mapStateToProps)(AccountsScreen);
