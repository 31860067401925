import React from 'react';
import UserDashboardContent from './UserDashboardContent';

function UserDashboard() {
  return (
    <div className='admin-dashboard'>
      <UserDashboardContent />
    </div>
  );
}

export default UserDashboard;
